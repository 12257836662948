import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Notifications } from 'react-push-notification'
import { Background, EmptyPage, ErrorHandler, TopMenu } from '.'
import AppNotifications from './AppNotifications'
import { FC, prefetchQueries, useCQuery } from '@Common/Services'
import { Login, Spinner, Updater, Flex } from '..'
import state, { resetStates } from '@Common/State'
import { useMount } from 'react-use'

export const AppContainer = ({ children, basename, appState }) => (
  <ErrorHandler appState={appState}>
    <ReactQueryDevtools initialIsOpen={false} />
    <BrowserRouter basename={basename}>
      <Notifications />
      <Updater />
      <AppNotifications />
      <AppSetup>
        {children}
      </AppSetup>
    </BrowserRouter>
  </ErrorHandler>
)

export const logoutFn = () => {
  resetStates(state)
  window.location.href = '/home'
}

const AppSetup = ({ children }) => {
  const [{ jwt }, setAuth] = state.auth.useState()
  const { data: agency, isSuccess } = useCQuery('agency')

  useMount(async () => {
    if (FC.authenticated || !jwt) return true
    const { user } = await FC.login({ strategy: 'jwt', accessToken: jwt })
    if (!FC.authenticated) return logoutFn()

    setAuth({
      jwt,
      userId: user._id,
      userAgency: user.agency,
      userFullName: `${user.name} ${user.lastName}`,
      codSociety: user?.agencyInfo?.codSociety || '',
      opUnit: user?.agencyInfo?.opUnit || '',
      networkAdmin: user?.networkAdmin || false,
      admin: user?.security === 'admin',
      usrAtl: user?.usrAtl,
      atlanteId: user?.atlanteId
    })

    prefetchQueries()
  })

  if (jwt && (!FC.authenticated || !isSuccess)) return <Flex fw fh><Spinner /></Flex>

  return (
    <>
      <Background customBackgroundUrl={agency?.customBackgroundUrl || undefined}>
        {(!FC.authenticated)
          ? <Login />
          : (
            <>
              <TopMenu />
              <EmptyPage>
                {children}
              </EmptyPage>
            </>)}
      </Background>
    </>
  )
}
