import React, { useRef, useState } from 'react'
import { Messages } from 'primereact/messages'
import { Card, Button, Input, Spinner, Modal, Flex, InputOld } from '..'
import state from '@Common/State'
import { FC, getHost, getUrlParams, prefetchQueries } from '@Common/Services'
import ModalAskReset from './ModalAskReset'
import { useMount } from 'react-use'
import ResetPassword from './ResetPassword'
import DataSelection from './DataSelection'

const Login = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState('')
  const setAuth = state.auth.useState()[1]
  const [userInfo, setUserInfo] = useState(null)

  const messages = useRef(null)
  const refModal = useRef(null)

  const host = getHost()

  const [passwordResetToken, id] = getUrlParams(['passwordResetToken', 'id'])

  useMount(() => {
    if (passwordResetToken && id) setPage('resetPassword')
    else setPage('')
    setLoading(false)
  })

  const showError = (summary) => {
    messages?.current?.show({ severity: 'error', closable: false, summary })
    setWaiting(false)
  }

  const handleSubmit = async () => {
    setWaiting(true)
    const { user, accessToken } = await FC.login({
      strategy: 'local',
      phone: email,
      password
    })

    /* Handling error */
    if (!user || !user.agency || !accessToken) {
      showError('Utente o Password non riconosciuti.')
      return false
    }

    // if (!FC.authenticated) return doLogout()

    /* Handling Atlante login - codSociety, opUnit & operator selection */
    if (user?.activeCompanies) {
      setUserInfo({
        jwt: accessToken,
        userId: user._id,
        userAgency: user.agency,
        userFullName: `${user.name} ${user.lastName}`,
        activeCompanies: user.activeCompanies,
        atlanteId: user.atlanteId
      })
      setPage('dataSelection')
      return
    }

    /* Handling login. */
    prefetchQueries()
    const { codSociety = '', opUnit = '' } = await FC.service('agencies').get(user.agency)
    setAuth({
      jwt: accessToken,
      userId: user._id,
      userAgency: user.agency,
      userFullName: `${user.name} ${user.lastName}`,
      usrAtl: user?.usrAtl,
      networkAdmin: user?.networkAdmin || false,
      admin: user?.security === 'admin',
      atlanteId: user?.atlanteId,
      codSociety,
      opUnit
    })
  }

  if (loading) return <Spinner />
  if (page === 'resetPassword') return <ResetPassword />
  if (page === 'dataSelection') return <DataSelection userInfo={userInfo} />
  return (
    <>
      <Modal
        ref={refModal}
        header='Password reset'
        content={
          <ModalAskReset
            closeModal={() => refModal?.current?.hide()}
            setMessage={(summary) => messages?.current?.show({ severity: 'success', closable: false, summary: 'Se l\'indirizzo indirizzo esiste, verrà inviata una mail!' })}
          />
        }
      />
      <Flex wrap fw>
        <Card
          style={{
            width: '600px',
            height: '100vh',
            padding: 50,
            borderRadius: 0
          }}
        // fadeIn
        >

          <img src={host[0].logo} style={{ width: '100%', marginTop: '30%' }} alt='logo' />
          <Messages
            style={{ margin: '10px' }}
            ref={messages}
          />
          <Flex>
            <Input
              id='email'
              placeholder='email o UserID Atlante Plus'
              value={email}
              onChange={({ email }) => {
                setEmail(email)
              }}
              style={{ width: 400, margin: '10px' }}
            />
            <InputOld
              password
              placeholder='password'
              value={password}
              onChange={({ password }) => {
                setPassword(password)
              }}
              style={{
                borderRadius: 20,
                width: 400,
                margin: '10px'
              }}
              onEnter={handleSubmit}
            />
            {waiting
              ? <Spinner />
              : (
                <Button
                  label='Accedi'
                  icon='symbol-1'
                  disabled={waiting}
                  onClick={handleSubmit}
                  style={{ marginBottom: 10, marginTop: 10 }}
                />)}
            <Button
              label='Hai dimenticato la password?'
              disabled={waiting}
              style={{ marginTop: 10 }}
              /* this is not a class method, so we -need- the arrow */
              onClick={() => {
                refModal.current.show()
                // showPasswordReset()
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </>
  )
}

export default Login
